// PMc = PMm*(1+(H%/100))^(-(H%/Kux))

// PMc = PM compensato
// PMm = PM misurato
// H% = Umidità relativa
// Kux = costante rilevata.
export const applyKuxFormula = (pm, hum, kux, round) => {
  const pmc = pm * Math.pow(1 + hum / 100, -(hum / kux));

  // console.log(
  //   `Generated value for ${kux}: from ${pm} to ${pmc}. Hum is ${hum}`
  // );
  return round ? Math.round(pmc * 100) / 100 : pmc;
};
