import React, { PureComponent } from 'react';
import I18n from '../../models/i18n';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { getData } from '../../lib/utils/httputils';

class Last24h extends PureComponent {
  state = {
    error: null,
    data: undefined
  };
  render() {
    const { data, error } = this.state;
    let pm25gauge;
    let noxGauge;
    let o3gauge;
    let pm5val;
    let noxval;
    let o3val;
    if (data) {
      pm5val = Number.parseFloat(data.data.PM2_5);
      pm25gauge = pm5val < 10 ? 'green' : pm5val < 25 ? 'yellow' : pm5val < 50 ? 'red' : 'black';
      noxval = Number.parseFloat(data.data.nox);
      noxGauge = noxval < 200 ? 'yellow' : noxval < 400 ? 'red' : 'black';
      console.log(data.data.o3)
      o3val = Number.parseFloat(data.data.o3 ?? 0);
      o3gauge = o3val < 120 ? 'green' : o3val < 180 ? 'yellow' : o3val < 240 ? 'red' : 'black';
    }
    const { desc, raw, showTitle } = this.getGeoData();
    const type = this.props.match.params.type;
    if (type || raw) {
      return (
        <div className="d-flex justify-content-between">
          {error && <div className="alert alert-danger">{error}</div>}
          {data && (
            <>
              {(!type || type === 'pm5') && (
                <div className="text-center">
                  {showTitle === '1' && <h4 className="mt-3">Microparticelle PM 2.5</h4>}
                  <img className="img-fluid" alt={pm25gauge} src={`/assets/gauge/gauge-${pm25gauge}.png`} />
                  <h2>{pm5val.toFixed(2)}</h2>
                </div>
              )}
              {(!type || type === 'nox') && (
                <div className="text-center">
                  {showTitle === '1' && <h4 className="mt-3">Ossidi di azoto NOx</h4>}
                  <img className="img-fluid" alt={pm25gauge} src={`/assets/gauge/three-colors/gauge-${noxGauge}.png`} />
                  <h2>{noxval.toFixed(2)}</h2>
                </div>
              )}
              {(!type || type === 'o3') && (
                <div className="text-center">
                  {showTitle === '1' && <h4 className="mt-3">Ozono O3</h4>}
                  <img className="img-fluid" alt={pm25gauge} src={`/assets/gauge/gauge-${o3gauge}.png`} />
                  <h2>{o3val.toFixed(2)}</h2>
                </div>
              )}
            </>
          )}
        </div>
      );
    }
    return (
      <div className="container mt-4">
        <div className="shadow-lg card mb-4">
          <div className="card-header graph-header">
            <div className="row">
              <div className="col-10">
                <div className="card-header-text">
                  <h3 className="chart-title py-2">{I18n.translate('stats', 'last_24h')}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            {error && <div className="alert alert-danger">{error}</div>}
            {data && (
              <>
                <div className="row mb-3">
                  <div className="col-12 col-md-4 text-center">
                    <h4>Microparticelle PM 2.5</h4>
                    <img className="img-fluid" alt={pm25gauge} src={`/assets/gauge/gauge-${pm25gauge}.png`} />
                    <h2>{pm5val.toFixed(2)}</h2>
                  </div>
                  <div className="col-12 col-md-4 text-center">
                    <h4>Ossidi di azoto NOx</h4>
                    <img
                      className="img-fluid"
                      alt={pm25gauge}
                      src={`/assets/gauge/three-colors/gauge-${noxGauge}.png`}
                    />
                    <h2>{noxval.toFixed(2)}</h2>
                  </div>
                  <div className="col-12 col-md-4 text-center">
                    <h4>Ozono O3</h4>
                    <img className="img-fluid" alt={pm25gauge} src={`/assets/gauge/gauge-${o3gauge}.png`} />
                    <h2>{o3val.toFixed(2)}</h2>
                  </div>
                </div>
                <em>{desc || ''}</em>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadData().catch(console.error);
  }

  loadData = async () => {
    const { lat, lng, range, all, type } = this.getGeoData();
    if (lat === undefined || lng === undefined || range === undefined || isNaN(lat) || isNaN(lng) || isNaN(range)) {
      this.setState({ error: 'Missing required parameters' });
      return;
    }
    try {
      const data = await getData(
        `/api/v1/stats/last24h/?lat=${lat}&lng=${lng}&range=${range}&values=PM2_5,nox,o3${all ? '&all=' + all : ''}${
          type ? '&type=' + type : ''
        }`
      );
      this.setState({ data });
    } catch (e) {
      this.setState({ error: e.message });
    }
  };

  getGeoData = () => {
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { lat, lng, range, desc, all, type, raw, showTitle } = queries;
    return { lat, lng, range, desc, all, type, raw, showTitle };
  };
}

export default withRouter(Last24h);
